/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faFilter } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import Plotly from "plotly.js-basic-dist";
import { useEffect, useState } from "react";
import createPlotlyComponent from "react-plotly.js/factory";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { AVOS_GRAPH_COLORS } from "../../../../actions/Layers/constants";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { isFilledArray } from "../../../../utils";
import { Loading } from "../../../Helper/Loading";

const Plot = createPlotlyComponent(Plotly);

function onlyUnique<T>(value: T, index: number, self: T[]): boolean {
    return self.indexOf(value) === index;
}

const isValidNumber = (number: any): boolean => (number > 0 || parseFloat(number) === 0) && number !== "" && number !== null && number !== undefined;


export default function RipeningGraph() {
    const checks = useSelector((state: any) => state.layers.checks);
    const config = useConfig();
    const layer = useSelector((state: any) => state.layers.current);
    const isLoading = useSelector((state: any) => state.layers.isLoading);
    const [ripeningFieldIndex, setRipeningFieldIndex] = useState(0);
    const [ignoreCheckLocations, setIgnoreCheckLocations] = useState([] as string[]);

    if (isLoading || !checks) {
        return <Loading />;
    }
    useEffect(() => {
        setIgnoreCheckLocations([]);
    }, [layer?.id, setRipeningFieldIndex, layer.fruit_type]);

    useEffect(() => {
        setRipeningFieldIndex(0);
    }, [layer?.id, layer.fruit_type]);

    const fruit_type = config.get_fruit_type(layer.fruit_type);


    // TODO: create tab for every ripening field
    const the_field = fruit_type?.ripening_fields?.[ripeningFieldIndex];

    if (!the_field?.fieldname_getter) {
        return null;
    }


    const ordered = checks.sort((a: any, b: any) => a.test_id - b.test_id).filter((i: any) => isValidNumber(i[the_field.fieldname_getter]));

    const checkLocations = ordered.map((i) => i.location).filter(onlyUnique).map((location: any) => ({
        value: location,
        label: config.get_location(layer, { location })?.text || location,
    }));


    const filtered_checks = ordered.filter((i) => !ignoreCheckLocations.includes(i.location));

    const avos_green = AVOS_GRAPH_COLORS.AVOS_SUCCESS;
    let graphs = [];


    // * SELF, show one line for this layer
    const created_array = filtered_checks.map((i: any) => dayjs(i.created).toISOString());

    graphs = [config_scatter_serie(
        created_array,
        filtered_checks.map((i: any) => i[the_field.fieldname_getter]),
        avos_green,
        the_field.label,
    )] as any;
    if (the_field.fieldname_getter === "pressure_avg") {
        // * use smart sampling to add upper and lower bounds
        const upper_bound_field = `${the_field.fieldname_getter}_upper_bound`;
        const lower_bound_field = `${the_field.fieldname_getter}_lower_bound`;
        graphs = [
            ...graphs,
            config_scatter_serie(
                created_array,
                filtered_checks.map((i: any) => i[upper_bound_field]),
                "#888",
                "Upper Bound",
            ),
            config_scatter_serie(
                created_array,
                filtered_checks.map((i: any) => i[lower_bound_field]),
                "#777",
                "Lower Bound",
            ),
        ] as any;
    }

    const range = [0, Math.ceil(Math.max(...filtered_checks.flatMap((i: any) => [i[the_field.fieldname_getter], i.pressure_avg_upper_bound])) * 1.2)];

    const layout = {
        autosize: true,
        showlegend: false,
        margin: {
            l: 50,
            r: 10,
            t: 0,
            b: 30,
            pad: 0
        },
        yaxis: {
            fixedrange: true,
            title: the_field.label,
            showgrid: false,
            range,
            autorange: the_field.higher_value_is_more_ripe ? "reversed" : false
        },
        xaxis: {
            fixedrange: true,
            showgrid: false,
        },
        shapes: [],
        annotations: [],
    };


    if (isFilledArray(the_field.categories)) {
        // * add swimming lanes per category
        const categories = the_field.categories.map((i: any) => ({
            ...i,
            label: i.label,
            lower: i.gt || i.gte || 0,
            upper: i.lt || i.lte || 100,
        }));

        // Add swimming lanes to the graph layout
        const swimmingLanes = categories.map((category) => ({
            type: "rect",
            x0: 0, // Start at the left border
            x1: 1, // End at the right border
            xref: "paper", // Makes x0 and x1 relative to the entire plot width
            y0: parseFloat(category.lower),
            y1: parseFloat(category.upper),
            fillcolor: category.color || "#D3D3D3",
            opacity: 0.2,
            line: {
                width: 0
            },
            layer: "below", // Ensure swimming lanes are drawn behind the graph lines
        }));
        // Add annotations for each category
        const annotations = categories.map((category) => ({
            x: 0,
            xref: "paper",
            y: (parseFloat(category.lower) + parseFloat(category.upper)) / 2,
            yref: "y",
            text: category.label,
            showarrow: false,
            font: {
                size: 12,
                color: "#4C4E52",
            },
            xanchor: "left",
            yanchor: "middle",
            position: "left"
        }));

        layout.shapes = swimmingLanes;
        layout.annotations = annotations;


    }


    return (
        <>
            <div className="d-flex">
                <div>
                    <h3 className="mb-0">{`${the_field?.label} Development` }</h3>
                </div>
            </div>
            <div className="d-flex justify-content-between">
                {fruit_type?.ripening_fields?.length > 0 && <div className="pt-2">
                    {fruit_type?.ripening_fields?.map((field, index) => <Button
                        key={index}
                        outline={index !== ripeningFieldIndex}
                        size="sm"
                        color="secondary"
                        className="me-1"
                        onClick={() => setRipeningFieldIndex(index)}
                    >{field.label}</Button>)}
                </div>}
                {checkLocations.length > 1 && <div className="pt-2 ms-auto">
                    {checkLocations.map((item: any, index: number) => <Button
                        key={index}
                        outline={ignoreCheckLocations.includes(item?.value)}
                        size="sm"
                        color="secondary"
                        className="ms-1"
                        onClick={() => setIgnoreCheckLocations((prev: any) => {
                            if (prev.includes(item.value)) {
                                return prev.filter((i: any) => i !== item.value);
                            }
                            return [...prev, item.value];
                        })}
                    ><FontAwesomeIcon icon={faFilter}></FontAwesomeIcon> {item.label}</Button>)}
                </div>}
            </div>

            <div css={css`min-height: 20rem;`} className="pt-2">
                {filtered_checks.length === 0 && <div className="text-center d-flex align-items-center justify-content-center" css={css`min-height: 24rem;background-color: #f8f8f8;`}>
                    <p className="text-muted mb-0">No data available<br></br>Start a check to track {the_field.label} progress</p>
                </div>}
                {filtered_checks.length > 0
                && <Plot
                    data={graphs}
                    useResizeHandler={true}
                    style={{ width: "100%", height: "100%" }}
                    layout={layout as any}
                    config={{
                        displayModeBar: false,
                        displaylogo: false,
                        staticPlot: true,
                    }}
                />}
            </div>
        </>
    );
}


function config_scatter_serie(created, values, color, name) {
    return {
        type: "scatter",
        x: created,
        y: values.map(parseFloat),
        mode: "lines+points+markers",
        name,
        marker: {
            color,
            size: 10,
        },
        line: {
            color,
            width: 3,
        },
    };
}
