/** @jsxImportSource @emotion/react */
import { LAYER_TYPE } from "../../../../actions/Layers/constants";
import useConfig from "../../../../actions/Tenants/config/configHook";
import MetaForm from "../../../Forms/MetaForm";
import FieldConfigForm from "./FieldConfigForm";
import FormConfigForm from "./FormConfigForm";
import LayerComponentConfigForm from "./LayerComponentConfigForm";
import MetricCardsConfigForm from "./MetricCardsConfigForm";
import RequiredImageConfigForm from "./RequiredImageConfigForm";
import SanitizeUnexpectedFields from "./SanitizeUnexpectedFields";
import SpecialActionsConfigForm from "./SpecialActionsConfigForm";
import { yesOrNoRadio } from "./util";

export const defaultLayerType = {
    text: "Pallet",
    value: "pallet",
    meta_display: [],
    arrival_check_meta_display: []
};

export default function LayerTypeConfigForm({ config_data, setConfigData }) {
    const config = useConfig();

    const form = [
        {
            label: "Label",
            description: "What is the label of this layer type. Used in the button.",
            name: "text",
            type: "text"
        },
        {
            label: "Value",
            description: "What is the slug or identifier of this layer type. Used in the database and in urls.",
            name: "value",
            type: "single-select",
            options: Object.keys(LAYER_TYPE).map((i) => ({ value: LAYER_TYPE[i], label: i }))
        },
        {
            label: "Parent layer type",
            description: "Can this layer have parents? If so, select the type of the parent.",
            name: "parent_layer_type",
            type: "single-select",
            options: Object.keys(LAYER_TYPE).map((i) => ({ value: LAYER_TYPE[i], label: i }))
        },
        {
            label: "Children layer type",
            description: "Can this layer have children? If so, select the type of the child.",
            name: "children_layer_type",
            type: "single-select",
            options: Object.keys(LAYER_TYPE).map((i) => ({ value: LAYER_TYPE[i], label: i }))
        },
        {
            label: "Creatable",
            name: "is_createable",
            description: "Can this layer be created by the user? Select no for subpallets for example.",
            ...yesOrNoRadio
        },
        {
            type: "element",
            name: "meta_display",
            el: <FieldConfigForm
                fields={config_data.meta_display || []}
                description="What fields to display on the left side of the layer index"
                useDisplayFilter={["fruit_types", "exclude_fruit_types"]}
                name="meta_display"
                title="Layer context"
                setArray={(meta_display) => setConfigData({ ...config_data, meta_display })} />
        },
        {
            type: "element",
            name: "meta_form",
            el: <FormConfigForm
                fields={config_data.meta_form || []}
                useDisplayFilter={["fruit_types", "exclude_fruit_types"]}
                description="Form for the layer edit modal"
                name="meta_form"
                title="Layer Meta Form"
                setArray={(meta_form) => setConfigData({ ...config_data, meta_form })} />
        },
        {
            label: "Label format",
            description: "Regex to be used to validate the regex",
            type: "text",
            name: "label_format"
        },
        {
            label: "Invalid hint",
            description: "A hint to show to the user if the format is invalid",
            name: "invalid_hint",
            type: "text"
        },
        {
            label: "Show edit button",
            name: "show_edit_button",
            description: "Show edit button on the left side of layer index",
            ...yesOrNoRadio
        },
        {
            label: "Show allocate button",
            name: "show_allocate_button",
            description: "Show allocate button on the left side of layer index, hidden by default",
            ...yesOrNoRadio
        },
        {
            label: "Show link layer button",
            name: "show_link_layer_button",
            description: "Show link layer button on the left side of layer index. Please also configure either a parent or a child layer type. Can also be set to  a string to setup a next action.",
            type: "single-select",
            options: [...yesOrNoRadio.options, ...config.check_locations.map(({ value }) => ({ value: `start-${value}`, label: `start-${value}` }))]
        },
        {
            label: "Start action",
            name: "start_action",
            description: "What is the starting action after the user created this layer? Options: link-parent, edit, view,start-<check type> e.g. start-arrival_check",
            type: "text"
        },
        {
            label: "Show images",
            name: "show_images",
            type: "element",
            el: <RequiredImageConfigForm
                fields={config_data.show_images || []}
                description="If you want pictures to be taken of the layer. Configure the type of images here. Applies to all checks under this layer, but can be overrules by images configured on the check."
                name="show_images"
                title="Required images"
                setArray={(show_images) => setConfigData({ ...config_data, show_images })} />
        },
        {
            label: "Special actions",
            type: "element",
            name: "special_actions",
            el: <SpecialActionsConfigForm
                fields={config_data.special_actions || []}
                description="Define a list of magic buttons to do multiple actions in one. Create and allocate a pallet to intake and start an intake check for example."
                name="special_actions"
                title="Special actions"
                setArray={(special_actions) => setConfigData({ ...config_data, special_actions })} />
        },
        {
            label: "Components on layer index",
            type: "header"
        },
        {
            label: "Show checks",
            name: "show_checks",
            type: "element",
            el: <LayerComponentConfigForm
                subconfig={config_data.show_checks}
                title="Show checks"
                description="Show checks in calender overview"
                setSubconfig={(show_checks) => setConfigData({ ...config_data, show_checks })}
                name="show_checks"
                attributes={["view", "filter_field", "filter_location", "card_type"]}
            />
        },
        {
            label: "Show children",
            name: "show_children",
            type: "element",
            el: <LayerComponentConfigForm
                subconfig={config_data.show_children}
                title="Show children"
                description="Show a table with children. Pallets on a PO for example."
                setSubconfig={(show_children) => setConfigData({ ...config_data, show_children })}
                name="show_children"
                attributes={["view", "fields", "title", "filter_field", "filter_location"]}
            />
        },
        {
            label: "Show ripening progress",
            name: "show_ripening_progress",
            ...yesOrNoRadio

        },
        {
            label: "Show pressure class history",
            name: "show_ripening_progress_pressure_history_table",
            ...yesOrNoRadio
        },
        {
            label: "Show general notes",
            name: "show_general_notes",
            ...yesOrNoRadio
        },
        {
            label: "Show lab check",
            name: "show_lab_check",
            type: "element",
            el: <LayerComponentConfigForm
                subconfig={config_data.show_lab_check}
                title="Lab check"
                description="Show lab check."
                setSubconfig={(show_lab_check) => setConfigData({ ...config_data, show_lab_check })}
                name="show_lab_check"
                attributes={["title"]}
            />
        },
        {
            label: "Show Metric Cards",
            name: "show_metric_cards",
            type: "element",
            el: <MetricCardsConfigForm
                fields={config_data.show_metric_cards || []}
                description="Show metric cards on the layer index"
                name="show_metric_cards"
                title="Metric Cards"
                setArray={(show_metric_cards) => setConfigData({ ...config_data, show_metric_cards })} />

        },
        {
            label: "Show arrival check",
            name: "show_arrival_check",
            type: "element",
            el: <LayerComponentConfigForm
                subconfig={config_data.show_arrival_check}
                title="Arrival check"
                description="Show arrival check."
                setSubconfig={(show_arrival_check) => setConfigData({ ...config_data, show_arrival_check })}
                name="show_arrival_check"
                attributes={["title", "fields"]}
            />
        },
        {
            label: "Show advance ripening",
            name: "show_advance_ripening",
            type: "element",
            el: <LayerComponentConfigForm
                subconfig={config_data.show_advance_ripening}
                title="Advance ripening"
                description="Show advance ripening"
                setSubconfig={(show_advance_ripening) => setConfigData({ ...config_data, show_advance_ripening })}
                name="show_advance_ripening"
                attributes={["title", "fields"]}
            />
        },
        {
            label: "Show upload reports",
            name: "show_upload_reports_button",
            description: "Show upload reports button.",
            ...yesOrNoRadio
        },
        {
            label: "Hide sub pallets",
            name: "hide_sub_pallets",
            description: "Hide sub pallets.",
            ...yesOrNoRadio
        },
        {
            label: "Allow remove allocation",
            name: "allow_remove_allocation",
            description: "Allow remove allcation of the layer.",
            ...yesOrNoRadio
        },
        {
            label: "Show box weight check",
            name: "show_box_weight_check",
            type: "element",
            el: <LayerComponentConfigForm
                subconfig={config_data.show_box_weight_check}
                title="Box weight check"
                description="Show box weight check."
                setSubconfig={(show_box_weight_check) => setConfigData({ ...config_data, show_box_weight_check })}
                name="show_box_weight_check"
                attributes={["title"]}
            />
        },
    ];

    return <>
        <SanitizeUnexpectedFields form={form} setObject={setConfigData} object={config_data} />
        <MetaForm meta={form} setValue={(field, value) => setConfigData({ ...config_data, [field]: value })} object={config_data} config={config} />
    </>;
}
